.cmp-navigation {
}
.cmp-navigation__group {
}
.cmp-navigation__item {
}
.cmp-navigation__item--active {
}
.cmp-navigation__item-link {
  border-bottom-color: transparent;
}
.nav-flyout_trigger {
}
.disable-parent {
  & li.cmp-navigation__item--level-0>a {
    pointer-events: none;
  }
}
cmp-navigation-mobile_nav .cmp-navigation__item--level-0>a {
  pointer-events: none;
}