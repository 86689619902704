.carousel {
    top: calc(25% + 41px);


    .splide__track {
        overflow: hidden;
        @media only screen and (min-width: $screen-tablet) {
            overflow: visible;
            padding-left: 15% !important;
            padding-right: 15% !important;
            padding-left: calc(8.3333% + 16px * 3) !important;
            padding-right: calc(8.3333% + 16px * 3) !important;
        }
    }

    .cmp-teaser__content {
        top: 567px;
        z-index: 500;
        margin: 0 calc(8.333% + 16px);
        @media only screen and (min-width: $screen-tablet) {
            margin: 0 32px;
        }
    }

    .cmp-teaser__image {
        z-index: 300;
        object-fit: cover;
        .cmp-image__image {
            aspect-ratio: 16/9;
            object-fit: cover;
            @media only screen and (min-width: $screen-tablet) {
                aspect-ratio: 4/3;
            }
        }
    }

    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
    }

    //== hide teaser content for inactive slides 
    .splide__slide {
        .cmp-teaser__content {
            visibility: hidden;
            opacity: 0;
            transition: opacity 600ms;
        }
        &.is-active .cmp-teaser__content {
            visibility: visible;
            opacity: 1;
        }
    }

    //== carousel teaser font styles 
    .cmp-teaser__content {
        .cmp-teaser__title {
            font-size: $text-20;
            line-height: $text-28;
            font-weight: $medium-weight;
            margin-top: 25px;
            color: $text-black;

            @media only screen and (min-width: $screen-tablet) {
                font-size: $text-28;
                line-height: $text-32;
                margin-top: 31px;
            }
        }

        .cmp-teaser__description {
            margin-top: -8px;

            &,
            p {
                @include body-copy;
            }

            @media only screen and (min-width: $screen-tablet) {
                margin-top: -8px;
            }
        }
    }

    //== carousel teaser arrow styles
    .splide__arrow {
        // override the default splide arrow styles
        opacity: 1;

        svg {
            display: none;
        }
        background: unset;

        height: 33px;
        width: 33px;

        @media only screen and (min-width: $screen-tablet) {
            height: 50px;
            width: 50px;
        }

        &::after {
            content: "";
            display: block;
            opacity: 1;
            height: 33px;
            width: fill-available;
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAyNCA3Njg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB2aWV3Qm94PSIzMDcuOTYgMTg0LjA4IDQwOCAzOTkuOCI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzAwNEI4Nzt9Cgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxnIGlkPSJHcm91cF81NiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0NTIuODM3IDMwODYuNzg1KSByb3RhdGUoMTgwKSI+Cgk8ZWxsaXBzZSBpZD0iRWxsaXBzZV80IiBjbGFzcz0ic3QwIiBjeD0iLTI5NjQuOCIgY3k9IjI3MDIuOCIgcng9IjIwNCIgcnk9IjE5OS45Ii8+Cgk8cGF0aCBpZD0iU3VidHJhY3Rpb25fNiIgY2xhc3M9InN0MSIgZD0iTS0yOTExLjgsMjc3Ni40djczLjZsLTE2OC45LTE0Ny4ybDE2OC45LTE0Ny4ydjczLjZsLTg0LjQsNzMuNkwtMjkxMS44LDI3NzYuNHoiLz4KPC9nPgo8L3N2Zz4=");
            background-size: contain;
            background-repeat: no-repeat;

            @media only screen and (min-width: $screen-tablet) {
                height: 49px;
            }
        }

        &:hover:not(:disabled) {
            
            &::after {
                background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAyNCA3Njg7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB2aWV3Qm94PSIzMDcuOTYgMTg0LjA4IDQwOCAzOTkuOCI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzAwMzg2NTt9Cgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxnIGlkPSJHcm91cF81NiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0NTIuODM3IDMwODYuNzg1KSByb3RhdGUoMTgwKSI+Cgk8ZWxsaXBzZSBpZD0iRWxsaXBzZV80IiBjbGFzcz0ic3QwIiBjeD0iLTI5NjQuOCIgY3k9IjI3MDIuOCIgcng9IjIwNCIgcnk9IjE5OS45Ii8+Cgk8cGF0aCBpZD0iU3VidHJhY3Rpb25fNiIgY2xhc3M9InN0MSIgZD0iTS0yOTExLjgsMjc3Ni40djczLjZsLTE2OC45LTE0Ny4ybDE2OC45LTE0Ny4ydjczLjZsLTg0LjQsNzMuNkwtMjkxMS44LDI3NzYuNHoiLz4KPC9nPgo8L3N2Zz4=");
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 1;
                width: fill-available;
            }
        }
    }

    .splide__arrow--prev {
        left: calc(8.3333% + 48px - 50px);

        &::after {
            transform: scale(-1, 1);
        }

        @media only screen and (min-width: $screen-tablet) {
            left: calc(8.3333% + 48px - 50px);
        }
    }

    .splide__arrow--next {
        right: calc(8.3333% + 48px - 50px);
        @media only screen and (min-width: $screen-tablet) {
            right: calc(8.3333% + 48px - 50px);
        }
    }
}