// First level of container grid maxs out at 1670px
// Allow backgrounds to extend to edge of page
@media only screen and (min-width: $screen-max) {
  html:not(.aem-AuthorLayer-Edit) {
    .max-width-vhfc {
      > .cmp-teaser > .cmp-teaser__content,
      > .cmp-container > .aem-Grid {
        margin: 0 auto;
      }
      > .cmp-container > .aem-Grid {
        max-width: $screen-max;
      }

    }
  }
}

@media only screen and (min-width: $screen-phone) {
  .card-grid-auto {
    > .cmp-container > .aem-Grid {
      display: grid;
      gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;
      &::before,
      &::after {
        content: none;
      }
      > div[class*="aem-GridColumn"] {
        width: 100%;
        margin: 0;
      }
    }
  }
}
