.cmp-search {
  .cmp-search-header {
    position: relative;
  }

  .search-heading {
    width: calc(100% - 40px);

    @media only screen and (max-width: $screen-tablet) {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 12px;
    }
  }

  .search-close-btn {
    position: absolute;
    right: 0;
    top: -12px;
    border: 1px solid $ledcor-grey;

    width: 40px;
    height: 40px;
    color: $text-black;
    background-color: $ledcor-grey-30;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.25' height='14' viewBox='0 0 12.25 14'%3E%3Cg id='Group_74981' data-name='Group 74981' transform='translate(688 2821)'%3E%3Cpath id='Subtraction_1' data-name='Subtraction 1' d='M7 14H7L0 5.251H4.2L7 8.751l2.8-3.5H14L7 14Z' transform='translate(-693.251 -2807) rotate(-90)' fill='%23868382'/%3E%3Cpath id='Subtraction_6' data-name='Subtraction 6' d='M7 0H7L0 8.75H4.2L7 5.25l2.8 3.5H14L7 0Z' transform='translate(-684.5 -2807) rotate(-90)' fill='%23868382'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    &:hover {
      background-color: $accessibility-blue;
      color: $white;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.25' height='14' viewBox='0 0 12.25 14'%3E%3Cg id='Group_74981' data-name='Group 74981' transform='translate(688 2821)'%3E%3Cpath id='Subtraction_1' data-name='Subtraction 1' d='M7 14H7L0 5.251H4.2L7 8.751l2.8-3.5H14L7 14Z' transform='translate(-693.251 -2807) rotate(-90)' fill='%23FFFFFF'/%3E%3Cpath id='Subtraction_6' data-name='Subtraction 6' d='M7 0H7L0 8.75H4.2L7 5.25l2.8 3.5H14L7 0Z' transform='translate(-684.5 -2807) rotate(-90)' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E");
    }
    @media only screen and (max-width: $screen-tablet) {
      top: -15px;
      border: none;
      height: 38px;
    }
  }
}

.cmp-search__form {}

.cmp-search__field {}

.cmp-search__icon {}

.cmp-search__input {}

.cmp-search__loading-indicator {
  right: 40px;
  left: auto;
}

.cmp-search__clear {}

.cmp-search__clear-icon {}

.cmp-search__results {
  // overridden by xf_header.scss file
  padding: 20px;
  height: 509px;
  position: absolute;
  left: 0;
  right: 0;

  @media only screen and (min-width: $screen-phone) {
    left: 65%;
    right: 6%;
    width: 360px;
  }
}

.cmp-search__item {}

.cmp-search__item--is-focused {}

.cmp-search__item-mark {}

.cmp-search__item-title {}