//== Colors from Wireframes
//== https://xd.adobe.com/view/f3b57d36-8136-4ed2-a08b-db5253a55e76-a6e6/screen/b3d8466b-c342-409e-9b6f-2f3be42cd0a0

$accessibility-blue-10: #E9F2FD;
$ledcor-blue-5: #F4F9FE;
$ledcor-grey-15: #EAEAEA;
$accessibility-blue: #146FDC;
$ledcor-blue: #003E8E;
$ledcor-blue-80: #3364A4;
$ledcor-grey-40: #C6C6C6;
$ledcor-grey-30: #D9D9D9;
$ledcor-grey: #727070;
$ledcor-grey-20: #E3E2E2;
$black: #000000;
$ledcor-grey-10: #F1F0F0;
$white: #FFFFFF;
$ledcor-blue-super-dark: #081C3D;
$ledcor-blue-dark: #121F4D;
$accessibility-blue-dark: #1B4B9F;
$text-black: #000000;
$ledcor-black-80: #333333;
$text-black-90: #000000;
$hover-grey: #F5F5F5;
$ledcor-red: #EB1F2D;
$accessibility-blue-lighter: #1473e6;
$accessibility-blue-darker: #152C7E;
$accessibility-blue-contrast: #146FDC;
$ledcor-separator-blue: #2680EB;

//== VHFC Colours

$vhfc-blue: #004B87;
$vhfc-grey: #EFEFEF;
$vhfc-dark-grey: #707070;
$vhfc-dark-blue: #003865;
$vhfc-light-blue: #336F9F;
$vhfc-lightest-blue: #d1f6ff;

//== Color

$color-background: #ffffff;
$color-foreground: #000000;
$color-accent: #f129ff;
$color-accent-lighter: #d97ae8;
$color-accent-darker: #eb00fd;
$color-shade-1: #eceff1;
$color-shade-2: #cfd8dc;
$color-shade-3: #607d8b;
$color-shade-4: #455a64;

$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;
$default-link: #b729e0;

$color-text: $color-foreground;
$color-text-inverted: $color-background;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

//== Units

$base-unit: 8px;

//== Font

$font-family-primary: "klavika-web",  Arial, sans-serif;
$font-family-secondary: "klavika-web",  Arial, sans-serif;
$font-family: "klavika-web",  Arial, sans-serif;
$font-family-title: "klavika-web",  Arial, sans-serif;

$font-size: 16px;
$font-height: 1.5; // 24px

//== Font Weights

$thin-weight: 400;
$light-weight: 400;
$normal-weight: 400;
$medium-weight: 600;
$bold-weight: 700;
$heavy-weight: 700;

//== Text Measurements (Pixels to Rem)

$text-12: 0.75rem;
$text-14: 0.875rem;
$text-16: 1rem;
$text-20: 1.25rem;
$text-18: 1.125rem;
$text-24: 1.5rem;
$text-28: 1.75rem;
$text-30: 1.875rem;
$text-32: 2rem;
$text-36: 2.25rem;
$text-48: 3rem;
$text-56: 3.5rem;
$text-69: 4.3125rem;
$text-72: 4.5rem;
$text-84: 5.25rem;

//== Normal mode
$color-foreground: $text-black;
$color-background: $white;
$color-link: $vhfc-blue;

//== Dark mode
$color-foreground-dark: $white;
$color-background-dark: $vhfc-dark-blue;
$color-link-dark: $vhfc-blue;

//== Breakpoints

$screen-max: 1670px;
$screen-laptop-max: 1399px;
$screen-laptop: 1400px;
$screen-tablet-large: 1368px; // surface pro 7 landscape
$nav-breakpoint: 1070px;
$nav-breakpoint-max: 1069px;
$screen-tablet-max: 1023px;
$screen-tablet: 1024px;
$screen-phone-max: 767px;
$screen-phone: 768px;

//== Icons

$chevron-down: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTRsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE1TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==';
$chevron-up: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTI1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUzMC41MTUgOTUuODI4KSByb3RhdGUoLTkwKSIvPjwvZz48L3N2Zz4=';
$chevron-right: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.75' height='14' viewBox='0 0 8.75 14'%3E%3Cpath id='Arrow' d='M8.75 0h0L0 7l8.75 7V9.8L5.25 7l3.5-2.8V0Z' transform='translate(8.75 14) rotate(180)' fill='%232981eb'/%3E%3C/svg%3E";
$chevron-right-white: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.75' height='14' viewBox='0 0 8.75 14'%3E%3Cpath id='Arrow' d='M8.75 0h0L0 7l8.75 7V9.8L5.25 7l3.5-2.8V0Z' transform='translate(8.75 14) rotate(180)' fill='%23fff'/%3E%3C/svg%3E";

$ledcor-arrow-left-white: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.75' height='14' viewBox='0 0 8.75 14'%3E%3Cpath id='Arrow' d='M8.75 0h0L0 7l8.75 7V9.8L5.25 7l3.5-2.8V0Z' transform='translate(8.75 14) rotate(180)' fill='%23fff'/%3E%3C/svg%3E";
$ledcor-arrow-left-blue: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.75' height='14' viewBox='0 0 8.75 14'%3E%3Cpath id='Arrow' d='M8.75 0h0L0 7l8.75 7V9.8L5.25 7l3.5-2.8V0Z' transform='translate(8.75 14) rotate(180)' fill='%232981eb'/%3E%3C/svg%3E";

$vhfc-down-arrow: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8"><path id="Polygon_2" data-name="Polygon 2" d="M6.5,0,13,8H0Z" transform="translate(13 8) rotate(180)" fill="%23004B87"/></svg>';