.cmp-teaser {}
.cmp-teaser__image {}
.cmp-teaser__content {}
.cmp-teaser__pretitle {}
.cmp-teaser__title {}
.cmp-teaser__title-link {}
.cmp-teaser__description {}
.cmp-teaser__action-container {}
.cmp-teaser__action-link {}

.teaser.content-card {  
  .cmp-teaser {
    position: relative;
    min-height: 100%;
  }
  .cmp-teaser__link {
    aspect-ratio: 9 / 5;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: $screen-phone) {
      aspect-ratio: 1 / 1;
    }
    > a {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      outline-offset: 2px;
    }
  }
  .cmp-teaser__image {
    position: relative;
    width: 100%;
    height: 100%;
    img,
    .cmp-image {
      height: 100%;
      width: 100%;
    }
    @media only screen and (max-width: $screen-phone) {
      img {
        min-height: 101%;
      }
    }
    .cmp-image {
      overflow: hidden;
      position: absolute;
      background-color: #2e6db4;
    }
    img {
      object-fit: cover;
      object-position: center;
      transition: filter ease-out 300ms;
    }
  }
  .cmp-teaser__link {
    > a:hover {
      img {
        filter: blur(10px) brightness(1.2) contrast(0.7);
      }
    }
    .cmp-teaser__content {
      pointer-events: none;
    }
  }
  .cmp-teaser__content {
    position: relative;
    z-index: 1;
    width: calc(100% - 33.3333%);
    margin: 0.5rem 0;
    text-align: center;
  }
  .cmp-teaser__title {
    font-size: $text-28;
    line-height: $text-32;
    font-weight: $medium-weight;
    @media (min-width: $screen-tablet) {
      font-size: $text-32;
      line-height: $text-36;
    }
  }
  .cmp-teaser__title,
  .cmp-teaser__subtitle {
    margin: 0;
    padding: 0;
    color: $white;
  }
  .cmp-teaser__subtitle {
    @include heading-4;
  }
  .cmp-teaser__subtitle:not(:empty) {
    margin-top: 0.5rem;
  }
}
