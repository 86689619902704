//== XF Footer style, used on page template
.cmp-experiencefragment--footer>.xf-content-height {
  min-height: auto;
  margin: 0;
}

#main-footer {
  margin-top: $base-unit*8;
  padding: 26px 0 22px;
  border-top: 0.125em solid $color-shade-1; 
  background-color: $vhfc-grey;

  @media only screen and (min-width: $screen-phone) {
    padding: 26px 0 22px;
    
  }
  @media only screen and (min-width: $screen-tablet) {
    padding: 71px 0 66px;
  }

  //========= Image (social)  =============

   .cmp-image {
    width: 25px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    filter: none;
    margin-right: 20px;
    &:hover {
      filter: brightness(1.5);
    }

    .cmp-image__image {
      display: block;
      height: 25px;
      width: 100%;
      margin: 0 auto;
    }

    @media only screen and (min-width: $screen-tablet) {
      margin-right: 26px;
      width: 50px;
      .cmp-image__image {
        height: 50px;
        }
    }
  }

  .image ~ .text p {
    color: $text-black;
    font-weight: 300;
  }

  .navigation-style-container {
   margin-bottom: 7.5rem;

   .cmp-navigation__item {
     text-align: right;
   }
  }

  .navigation-style-container .cmp-text > p,
  .cmp-navigation__item-link {
    color: $text-black;
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: $text-black;
    @include body-copy;
    border: none;

  }

  .navigation-style-container .cmp-text > p,
  .cmp-navigation__item > a {
    padding: 12px 0;
    display: block;
    @media only screen and (min-width: $screen-tablet) {
      padding: 8px 0;
      margin: 2px 0;
    }
  }

  .cmp-navigation__item {
    margin: 0;
  }

  .centered-nav > .cmp-container > .aem-Grid {
    @media only screen and (max-width: calc($screen-phone - 1px)) {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
    }
  }

  //=================== Navigation ===================

  .cmp-navigation {
    display: block;

    .cmp-navigation__group {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }

  //=========== Navigation Style Text Containers ============

    &.cmp-navigation {
      @include small-copy;
      display: block;
    }
  
   // Right-most container, imitates looks of nav item

  .navigation-style-container {
    margin-bottom: 7.5rem;

    .navigation,
    .cmp-navigation__item {
      text-align: right;
    }

     li::marker {
      display: none;
      content: '';
    }

  }

  }

  //=========== VHFC CTA/Text Styles ============
  // VHFC Styles
  > div {
    @media only screen and (min-width: $screen-tablet) {
      display: block;
    }
  }
  
  // =========== Social Container (social images + copyright text) ===========
  .container > .cmp-container > .aem-Grid {
    .image {
      width: auto;
      @media only screen and (min-width: $screen-tablet) {
        margin-top: 26px;
      }
    }
  }

}


