@use "sass:math";
/*
** Mixins and Functions
*/

@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function rem($pxValue) {
  @return math.div(stripUnit($pxValue), stripUnit($font-size)) * 1rem;
}

/*
** Mobile Image Placement Modifiers
*/

@media only screen and (max-width: calc($screen-phone - 1px)) {
  .center-center-image {
    transform: scale(1.3) translate(-40%, -40%);
    width: auto;
    height: 100%;
    position: absolute;
    transform-origin: center center;
    left: 50%;
    top: 50%;
  }
  .top-left-image {
    transform: scale(1.3);
    transform-origin: top left;
    left: 0;
    top: 0;
  }
  .top-center-image {
    transform: scale(1.3) translateX(-40%);
    transform-origin: top center;
    left: 50%;
    top: 0;
  }
  .top-right-image {
    transform: scale(1.3);
    transform-origin: top right;
    left: auto;
    top: 0;
    right: 0;
  }
  .center-left-image {
    transform: scale(1.3) translateY(-40%);
    transform-origin: center left;
    left: 0;
    top: 50%;
  }
  .center-right-image {
    transform: scale(1.3) translateY(-40%);
    transform-origin: center right;
    left: auto;
    top: 50%;
    right: 0;
  }
  .bottom-left-image {
    transform: scale(1.3);
    transform-origin: bottom left;
    left: 0;
    top: auto;
    bottom: 0;
  }
  .bottom-center-image {
    transform: scale(1.3) translateX(-40%);
    transform-origin: bottom center;
    left: 50%;
    top: auto;
    bottom: 0;
  }
  .bottom-right-image {
    transform: scale(1.3);
    transform-origin: bottom right;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: $screen-phone) {
    .lg-center-center-image .cmp-image img,
    .lg-center-center-image {
      object-position: center center;
    }
    .lg-top-left-image .cmp-image img,
    .lg-top-left-image {
      object-position: top left;
    }
    .lg-top-center-image .cmp-image img,
    .lg-top-center-image {
      object-position: top center;
    }
    .lg-top-right-image .cmp-image img,
    .lg-top-right-image {
      object-position: top right;
    }
    .lg-center-left-image .cmp-image img,
    .lg-center-left-image {
      object-position: center left;
    }
    .lg-center-right-image .cmp-image img,
    .lg-center-right-image {
      object-position: center right;
    }
    .lg-bottom-left-image .cmp-image img,
    .lg-bottom-left-image {
      object-position: bottom left;
    }
    .lg-bottom-center-image .cmp-image img,
    .lg-bottom-center-image {
      object-position: bottom center;
    }
    .lg-bottom-right-image .cmp-image img,
    .lg-bottom-right-image {
      object-position: bottom right;
    }
}
.show-full-image .cmp-image img,
.show-full-image {
    object-fit: contain;
}

/*
** Component Spacing Utility Classes
*/


/* Mobile Spacing Sizes */

.top-padding-xxl,
.top-padding-xl,
.top-padding-lg,
.top-padding-md {
    padding-top: $base-unit*3;
}

.top-margin-xxl,
.top-margin-xl,
.top-margin-lg,
.top-margin-md {
    margin-top: $base-unit*3;
}

.top-padding-sm {
    padding-top: $base-unit*2;
}
.top-margin-sm {
    margin-top: $base-unit*2;
}

.top-padding-xs {
    padding-top: $base-unit;
}
.top-margin-xs {
    margin-top: $base-unit;
}

.bottom-padding-xxl,
.bottom-padding-xl,
.bottom-padding-lg,
.bottom-padding-md {
    padding-bottom: $base-unit*3;
}

.bottom-margin-xxl,
.bottom-margin-xl,
.bottom-margin-lg,
.bottom-margin-md {
    margin-bottom: $base-unit*3;
}

.bottom-padding-sm {
    padding-bottom: $base-unit*2;
}
.bottom-margin-sm {
    margin-bottom: $base-unit*2;
}

.bottom-padding-xs {
    padding-bottom: $base-unit;
}
.bottom-margin-xs {
    margin-bottom: $base-unit;
}

.bottom-margin-none {
    margin-bottom: 0;
}

/* Tablet Spacing Sizes */

@media only screen and (min-width: $screen-phone) {

    .top-padding-xxl {
        padding-top: $base-unit*10;
    }
    .top-margin-xxl {
        margin-top: $base-unit*10;
    }
    
    .top-padding-lg,
    .top-padding-xl {
        padding-top: $base-unit*5;
    }
    .top-margin-lg,
    .top-margin-xl {
        margin-top: $base-unit*5;
    }

    .top-padding-md {
        padding-top: $base-unit*4;
    }
    .top-margin-md {
        margin-top: $base-unit*4;
    }

    .bottom-padding-xxl {
        padding-bottom: $base-unit*10;
    }
    .bottom-margin-xxl {
        margin-bottom: $base-unit*10;
    }
    
    .bottom-padding-lg,
    .bottom-padding-xl {
        padding-bottom: $base-unit*5;
    }
    .bottom-margin-lg,
    .bottom-margin-xl {
        margin-bottom: $base-unit*5;
    }

    .bottom-padding-md {
        padding-bottom: $base-unit*4;
    }
    .bottom-margin-md {
        margin-bottom: $base-unit*4;
    }

}

/* Laptop Spacing Sizes */

@media only screen and (min-width: $screen-laptop) {

    .top-padding-xxl {
        padding-top: $base-unit*20;
    }
    .top-margin-xxl {
        margin-top: $base-unit*20;
    }

    .top-padding-xl {
        padding-top: $base-unit*10;
    }
    .top-margin-xl {
        margin-top: $base-unit*10;
    }

    .bottom-padding-xxl {
        padding-bottom: $base-unit*20;
    }
    .bottom-margin-xxl {
        margin-bottom: $base-unit*20;
    }

    .bottom-padding-xl {
        padding-bottom: $base-unit*10;
    }
    .bottom-margin-xl {
        margin-bottom: $base-unit*10;
    }

}
