.cmp-accordion__item {
    background-color: $ledcor-grey-10;
    margin-bottom: $base-unit;
    box-shadow: 0px 3px 6px #0000001a;
    .cmp-accordion__panel {
      display: block;
      visibility: hidden;
      max-height: 0px;
      overflow: hidden;
      transition: max-height 250ms ease-out 50ms, visibility 0ms ease-out 300ms;
      > div {
        transition: opacity 250ms ease-out;
        opacity: 0;
        padding: 0px $base-unit*1.5 $base-unit*1.5 $base-unit*1.5;
        @media only screen and (min-width: $screen-phone) {
          padding: 0px $base-unit*3 $base-unit*3 $base-unit*3;
        }
      }
    }
    .cmp-accordion__panel--expanded {
      transition: max-height 300ms ease-out 0ms, visibility 0ms ease-out 0ms;
      max-height: 3000px;
      visibility: visible;
      > div {
        opacity: 1;
      }
    }
  
  }
  .cmp-accordion__icon {
    display: none;
  }
  .cmp-accordion__button {
    background-color: transparent;
    transition: background-color 150ms ease-out;
    padding: $base-unit*1.5;
    display: flex;
    justify-content: space-between;
    border-bottom-width: 0px;
    align-items: center;
    @media only screen and (min-width: $screen-phone) {
      padding: $base-unit*3;
    }
    &::after {
      content: '';
      background-image: url($chevron-down);
      background-repeat: no-repeat;
      width: $base-unit*4;
      min-width: $base-unit*4;
      height: $base-unit*2;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 0px 50%;
      filter: brightness(0.3);
      transform: rotateX(0deg);
      transition: transform 300ms ease-out;
      @media only screen and (min-width: $screen-phone) {
        background-position: 6px 0px;
        height: $base-unit*4;
      }
    }
    &:hover {
      background-color: $ledcor-grey-20;
    }
    &:hover, &:focus{
      text-decoration: none;
    }
    &[aria-expanded=true] {
      &::after {
        transform: rotateX(180deg);
      }
    }
  }
  
  span.cmp-accordion__title {
    @extend .dark-text;
    margin: 0;
    font-size: $text-20;
    line-height: $text-28;
    font-weight: $medium-weight;
    color: $text-black;

    @media only screen and (min-width: $screen-tablet) {
      font-size: $text-32;
      line-height: $text-32;
    }
   
    &:hover, &:focus{
      text-decoration: none;
    }
  }
  .aem-AuthorLayer-Edit {
    .cmp-accordion__panel {
      display: none;
    }
    .cmp-accordion__panel--expanded {
      display: block;
    }
  }