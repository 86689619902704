div#mobile-nav_trigger {
  display: block;
  position: absolute;
  top: 20px;
  right: 35px;
  margin: 7px 5px;
  // position higher than the magnifier 
  z-index: 1002;
  top: 5px;

  @media only screen and (min-width: $screen-phone) {
    right: 37px;
  }
  @media only screen and (min-width: $nav-breakpoint) {
    display: none;
  }
}

.list-collapsed {
  max-height: 0;
  overflow: hidden;
}

#nav-icon3 {
  width: 36px;
  height: 45px;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: $white;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  margin: 6px 0;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 10px;
}

#nav-icon3 span:nth-child(4) {
  top: 20px;
}

#nav-icon3.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

//=================== Mobile Navigation Flyout ===================

#mobileNav {
  display: block;

  @media only screen and (min-width: $screen-laptop) {
    display: none;
  }
}

.cmp-navigation-mobile_nav {
  top: 60px;
  position: absolute;
  width: 100%;
  z-index: 101;

  list-style: none;
  margin: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;

  background: $vhfc-dark-blue;

  .cmp-navigation__item.cmp-navigation__item--level-0 .cmp-navigation__group {
    max-height: 0;
    visibility: hidden;
    transition: max-height 0.3s ease-out, visibility 0s ease-out 0.3s;
  }
  .cmp-navigation__item.cmp-navigation__item--level-0.isExpanded .cmp-navigation__group {
    max-height: 2000px;
    visibility: visible;
    transition: max-height 0.6s ease-out, visibility 0s ease-out;
  }

  & li a[aria-haspopup]::after {
    background-color: $white;
    content: "";
    display: inline-block;
    -webkit-mask: url($vhfc-down-arrow);
    mask: url($vhfc-down-arrow);
    -webkit-mask-size: cover;
    mask-size: cover;
    pointer-events: none;
    width: 10px;
    height: 6px;
    transition: transform 0.3s ease-out;
    transform: rotate(0deg);
    margin-left: 12px;
  }

  & li {
    background-color: transparent;
    transition: background-color 0.3s ease-out;
    &.isExpanded {
      background-color: $vhfc-light-blue;
      > a::after {
        transform: rotate(180deg);
      }
      > a {
        background-color: transparent;
      }
    }

    margin: 0;

    &.cmp-navigation__item--level-0 {
      > .cmp-navigation__item-link {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      &:first-child > .cmp-navigation__item-link {
        padding-top: 18px;
      }
      &:last-child > .cmp-navigation__item-link {
        padding-bottom: 18px;
      }
    }
    .cmp-navigation__item--level-1 {
      &:first-child > .cmp-navigation__item-link {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 12px;
      }
    }
    & a,
    ul a {
      padding-left: 40px;
      padding-right: 55px;
      margin: 0;
    }

    & a {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      text-decoration: none;
      color: $white;
      text-align: left;
      font-size: $text-14;
      line-height: $text-18;
      font-weight: $normal-weight;
      text-transform: uppercase;
      &[aria-haspopup] {
        pointer-events: none;
      }
    }

    > a {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    & ul {
      background-color: transparent;
      list-style: none;
      margin: 0;
      padding: 0;

      & li a {
        margin: 0;
        text-align: left;
        color: $white;
        font-size: $text-14;
        line-height: $text-20;
        font-weight: $normal-weight;
        pointer-events: all;
        text-transform: none;


        &::after {
          content: unset;
        }
      }

      & li ul li a {
        color: $white;
        font-size: $text-14;
        line-height: $text-20;
        font-weight: $normal-weight;
        text-transform: none;
        pointer-events: all;
      }
    }
  }
  @media only screen and (min-width: $nav-breakpoint) {
    display: none;
  }
}