.cmp-image {
}
.cmp-image__link {
}
.cmp-image__image {
}
.cmp-image__title {
}

.image.grey-border {
  border: 1px solid $vhfc-dark-grey;
}

.image.blue-border {
  border: 1px solid $vhfc-blue;
}

.card-grid-auto .image {
  aspect-ratio: 7 / 4;
  margin: 0.5rem 0;
  overflow: visible;

  @media only screen and (min-width: $screen-phone) {
    aspect-ratio: 1;
    margin: 0;
  }

  .cmp-image {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cmp-image__image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    max-width: calc(100% - 3rem);
    max-height: calc(100% - 1rem);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (min-width: $screen-phone) {
      max-width: calc(100% - 2.5rem);
      max-height: calc(100% - 2.5rem);
    }
  }
  .cmp-image__link {
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
    outline-offset: -3px;
  }
}

/*
** Mobile Image Placement Modifiers
*/

@media only screen and (max-width: calc($screen-phone - 1px)) {

  .image {
    overflow: hidden;
    
    &.top-left,
    &.top-center,
    &.top-right,
    &.center-left,
    &.center-center,
    &.center-right,
    &.bottom-left,
    &.bottom-center,
    &.bottom-right {
      height: 320px;
      .cmp-image {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.image {
  &.top-left,
  &.top-center,
  &.top-right,
  &.center-left,
  &.center-center,
  &.center-right,
  &.bottom-left,
  &.bottom-center,
  &.bottom-right {
    .cmp-image > .cmp-image__image {
      @extend .center-center-image;
    }
  }

  &.top-left .cmp-image .cmp-image__image {
    @extend .top-left-image;
  }
  &.top-center .cmp-image .cmp-image__image {
    @extend .top-center-image;
  }
  &.top-right .cmp-image .cmp-image__image {
    @extend .top-right-image;
  }
  &.center-left .cmp-image .cmp-image__image {
    @extend .center-left-image;
  }
  &.center-right .cmp-image .cmp-image__image {
    @extend .center-right-image;
  }
  &.bottom-left .cmp-image .cmp-image__image {
    @extend .bottom-left-image;
  }
  &.bottom-center .cmp-image .cmp-image__image {
    @extend .bottom-center-image;
  }
  &.bottom-right .cmp-image .cmp-image__image {
    @extend .bottom-right-image;
  }
}
