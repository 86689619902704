.cmp-teaser {}
.cmp-teaser__image {}
.cmp-teaser__content {}
.cmp-teaser__pretitle {}
.cmp-teaser__title {}
.cmp-teaser__title-link {}
.cmp-teaser__description {}
.cmp-teaser__action-container {}
.cmp-teaser__action-link {}

// Common Teaser Styles
.teaser-title,
.teaser-hero {
  &.center-center .cmp-image img {
    object-position: center center;
  }
  &.top-left .cmp-image img {
    object-position: top left;
  }
  &.top-center .cmp-image img {
    object-position: top center;
  }
  &.top-right .cmp-image img {
    object-position: top right;
  }
  &.center-left .cmp-image img {
    object-position: center left;
  }
  &.center-right .cmp-image img {
    object-position: center right;
  }
  &.bottom-left .cmp-image img {
    object-position: bottom left;
  }
  &.bottom-center .cmp-image img {
    object-position: bottom center;
  }
  &.bottom-right .cmp-image img {
    object-position: bottom right;
  }
  @media only screen and (min-width: $screen-phone) {
    &.lg-center-center-image  .cmp-image img {
      object-position: center center;
    }
    &.lg-top-left-image  .cmp-image img {
      object-position: top left;
    }
    &.lg-top-center-image  .cmp-image img {
      object-position: top center;
    }
    &.lg-top-right-image  .cmp-image img {
      object-position: top right;
    }
    &.lg-center-left-image  .cmp-image img {
      object-position: center left;
    }
    &.lg-center-right-image  .cmp-image img {
      object-position: center right;
    }
    &.lg-bottom-left-image  .cmp-image img {
      object-position: bottom left;
    }
    &.lg-bottom-center-image  .cmp-image img {
      object-position: bottom center;
    }
    &.lg-bottom-right-image  .cmp-image img {
      object-position: bottom right;
    }
  }
  .cmp-teaser__image {
    position: relative;
    img,
    .cmp-image {
      height: 100%;
      width: 100%;
    }
    .cmp-image {
      overflow: hidden;
      position: absolute;
    }
    img {
      object-fit: cover;
    }
  }
  .cmp-teaser__content {
    width: 100%;

    @media only screen and (min-width: $screen-phone) {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 1.5rem 3.25rem 1.5rem 8.33334%;
      z-index: 1;
      background-color: rgba(255,255,255, 0.85);
      width: auto;
      max-width: 54.16667%;
      border-radius: 0px 2rem 2rem 0px;
    }
  }
  &.wide-content {
    .cmp-teaser__content {
      @media only screen and (min-width: $screen-phone) {
        max-width: calc(83.3333333% - 4rem);
      }
    }
    &.max-width-vhfc .cmp-teaser__content {
      @media only screen and (min-width: $screen-max) {
        max-width: 1327.68px;
      }
    }
  }
}

// Teaser Hero Style
.teaser-hero {
  position: relative;

  &.min-height-sm {
    .cmp-teaser__image {
      min-height: 12rem;
      @media only screen and (min-width: $screen-phone) {
        min-height: 24rem;
        aspect-ratio: 3 / 1;
        max-height: 48rem;
      }
    }
  }
  &.min-height-md .cmp-teaser__image,
  .cmp-teaser__image {
    min-height: 14.5rem;
    @media only screen and (min-width: $screen-phone) {
      min-height: 36rem;
      aspect-ratio: 2 / 1;
      max-height: 50rem;
    }
  }
  &.min-height-lg {
    .cmp-teaser__image {
      min-height: 18rem;
      @media only screen and (min-width: $screen-phone) {
        min-height: 48rem;
        aspect-ratio: 4 / 3;
        max-height: 68rem;
      }
    }
  }

  .cmp-teaser {
    display: flex;
    flex-direction: column-reverse;
  }
  .cmp-teaser__content {
    padding: 2.5rem calc(8.3333% + 1rem);
    width: auto;

    @media only screen and (min-width: $screen-phone) {
      padding: 2rem 4.75rem 2rem 8.33334%;
      border-radius: 0px 4rem 4rem 0px;
    }
    @media only screen and (min-width: $screen-tablet) {
      padding: 3rem 4rem 3rem 8.33334%;
    }
  }
  &.max-width-vhfc .cmp-teaser__content {
    @media only screen and (min-width: $screen-max) {
      // Half Screen - (Half $screen-max - (2 columns * $max-screen))
      padding-left: calc(50% - 695.833334px);
      max-width: 855px;
    }
  }
  .cmp-teaser__title {
    @include heading-1;
    margin: 0;
    @media (min-width: $screen-tablet) {
      font-size: $text-69;
    }
  }
}

// Teaser Title Style
.teaser-title {
  position: relative;

  &.min-height-sm {
    .cmp-teaser__image {
      min-height: 14rem;
      @media only screen and (min-width: $screen-phone) {
        min-height: 24rem;
      }
    }
  }
  &.min-height-md .cmp-teaser__image,
  .cmp-teaser__image {
    overflow: hidden;
    min-height: 18.75rem;
    @media only screen and (min-width: $screen-phone) {
      min-height: 31rem;
    }
  }
  &.min-height-lg {
    .cmp-teaser__image {
      min-height: 22rem;
      @media only screen and (min-width: $screen-phone) {
        min-height: 38rem;
      }
    }
  }

  .cmp-teaser__content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    max-width: calc(83.333334% - 2rem);
    padding: 1rem calc(8.3333% + 1rem);
    background-color: rgba(255,255,255, 0.85);
    @media only screen and (min-width: $screen-phone) {
      bottom: 50%;
      transform: translateY(50%);
      max-width: 54.16667%;
      padding: 1.5rem 3.25rem 1.5rem 8.33334%;
    }
  }
  &.max-width-vhfc .cmp-teaser__content {
    @media only screen and (min-width: $screen-max) {
      // Half Screen - (Half screen max - (2 columns * max screen))
      padding-left: calc(50% - 695.833334px);
      max-width: 984.583389px;
    }
  }

  .cmp-teaser__title {
    @include heading-1;
    margin: 0;
  }
}

