
.cmp-separator__horizontal-rule {
  background-color: $color-shade-1;
  border: none;
}

.separator {
  &:not(.ledcor, .small) {
    .cmp-separator__horizontal-rule {
      display: none;
    }
  }
  &.show-line {
    .cmp-separator__horizontal-rule {
      display: block;
      background-color: $accessibility-blue;
      margin-bottom: 0;
      margin-top: 0;
      height: 2px;
    }
  }
  &.xxs-space {
    padding: 0.0625rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 0.125rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 0.25rem 0;
    }
  }
  &.xs-space {
    padding: 0.125rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 0.25rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 0.5rem 0;
    }
  }
  &.sm-space {
    padding: 0.25rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 0.5rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 0.75rem 0;
    }
  }
  &.md-space {
    padding: 0.5rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 0.75rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 1rem 0;
    }
  }
  &.lg-space {
    padding: 0.75rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 1rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 1.5rem 0;
    }
  }
  &.xl-space {
    padding: 1rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 1.5rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 2rem 0;
    }
  }
  &.xxl-space {
    padding: 1.5rem 0;
    @media only screen and (min-width: $screen-tablet) {
      padding: 2rem 0;
    }
    @media only screen and (min-width: $screen-laptop) {
      padding: 4rem 0;
    }
  }
}

