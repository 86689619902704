
/**
*** Typography
**/


@mixin heading-1 {
  font-size: $text-36;
  line-height: $text-48;
  font-weight: $normal-weight;
  color: $vhfc-blue;
  @media (min-width: $screen-tablet) {
    font-size: $text-72;
    line-height: $text-84;
  }
}

@mixin heading-2 {
  font-size: $text-28;
  line-height: $text-36;
  font-weight: $medium-weight;
  color: $vhfc-blue;
  @media (min-width: $screen-tablet) {
    font-size: $text-48;
    line-height: $text-56;
  }
}

@mixin heading-3 {
  font-size: $text-20;
  line-height: $text-28;
  font-weight: $medium-weight;
  @media (min-width: $screen-tablet) {
    font-size: $text-32;
    line-height: $text-36;
  }
}

@mixin heading-4 {
  font-size: $text-18;
  line-height: $text-24;
  font-weight: $medium-weight;
  @media (min-width: $screen-tablet) {
    font-size: $text-28;
    line-height: $text-32;
  }
}

@mixin body-copy {
  font-size: $text-16;
  line-height: $text-24;
  font-weight: $normal-weight;
  @media (min-width: $screen-tablet) {
    font-size: $text-24;
    line-height: $text-36;
  }
}

@mixin small-copy {
  font-size: $text-14;
  line-height: $text-20;
  font-weight: $normal-weight;
  @media (min-width: $screen-tablet) {
    font-size: $text-16;
    line-height: $text-24;
  }
}

@mixin text-link {
  font-weight: $medium-weight;
  color: $vhfc-blue;
}

@mixin parent-link {
  font-size: $text-14;
  line-height: $text-18;
  @media (min-width: $screen-tablet) {
    font-size: $text-24;
    line-height: $text-30;
  }
}

@mixin child-link {
  font-size: $text-14;
  line-height: $text-20;
  @media (min-width: $screen-tablet) {
    font-size: $text-20;
    line-height: $text-24;
  }
}

@mixin dark-text {
  color: $text-black;
}

@mixin light-text {
  color: $white;
}

html,
body,
html body {
    font-size:   $font-size;
    font-family: $font-family-primary;
    line-height: $font-height;
    color:       $color-foreground;
    background:  $color-background;
}

@media (min-width: $screen-tablet) {

    body > * {
        font-size:   $text-24;
        line-height: $text-36;
    }

}

.visually-hidden,
.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
}

/**
*** Themes
**/

.dark-text {
  @include dark-text;
}

.light-text {
  @include light-text;
}

[style*="background-color:#FFFFFF;"],
[style*="background-color:#EFEFEF;"],
.light-container,
.light {
    h1, h2, h3, h4, h5, h6, blockquote, dt, dd, figcaption, li, p, a, span, label, textarea, select, input, button, table, th, td, tr, legend {
        @include dark-text;
    }
    h1, h2 {
      color: $vhfc-blue;
    }

}
[style*="background-color:#003865;"],
[style*="background-color:#004B87;"],
[style*="background-color:#336F9F;"],
[style*="background-color:#000000;"],
.dark-container,
.dark {
    h1, h2, h3, h4, h5, h6, blockquote, dt, dd, figcaption, li, p, a, span, label, textarea, select, input, button, table, th, td, tr, legend {
        @include light-text;
        text-decoration-color: $white;
    }
    blockquote,
    dt,
    dd,
    figcaption,
    li,
    span,
    label,
    select,
    p {

      a,
      button,
      a.trivial,
      button.trivial,
      .trivial {
        color: $vhfc-lightest-blue;
      }

      a:focus,
      a:hover,
      button:focus,
      button:hover,
      a.trivial:focus,
      button.trivial:focus,
      a.trivial:hover,
      button.trivial:hover,
      .trivial:focus,
      .trivial:hover {
        color: $vhfc-lightest-blue;
      }
    }
}

/**
*** Headings
**/

/* 

Heading Type Elements

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,

*/

.heading-1 h1, h1.heading-1, .heading-1 h2, h2.heading-1, .heading-1 h3, h3.heading-1, .heading-1 h4, h4.heading-1, .heading-1 h5, h5.heading-1, .heading-1 h6, h6.heading-1, .heading-1 p, p.heading-1, .heading-1 span, span.heading-1,
.heading-1, h1 {
    @include heading-1;
}

.heading-2 h1, h1.heading-2, .heading-2 h2, h2.heading-2, .heading-2 h3, h3.heading-2, .heading-2 h4, h4.heading-2, .heading-2 h5, h5.heading-2, .heading-2 h6, h6.heading-2, .heading-2 p, p.heading-2, .heading-2 span, span.heading-2,
.heading-2, h2 {
  @include heading-2;
}

.heading-3 h1, h1.heading-3, .heading-3 h2, h2.heading-3, .heading-3 h3, h3.heading-3, .heading-3 h4, h4.heading-3, .heading-3 h5, h5.heading-3, .heading-3 h6, h6.heading-3, .heading-3 p, p.heading-3, .heading-3 span, span.heading-3,
.heading-3, h3 {
  @include heading-3;
}
.heading-4 h1, h1.heading-4, .heading-4 h2, h2.heading-4, .heading-4 h3, h3.heading-4, .heading-4 h4, h4.heading-4, .heading-4 h5, h5.heading-4, .heading-4 h6, h6.heading-4, .heading-4 p, p.heading-4, .heading-4 span, span.heading-4,
.heading-4, h4,
.heading-5 h1, h1.heading-5, .heading-5 h2, h2.heading-5, .heading-5 h3, h3.heading-5, .heading-5 h4, h4.heading-5, .heading-5 h5, h5.heading-5, .heading-5 h6, h6.heading-5, .heading-5 p, p.heading-5, .heading-5 span, span.heading-5,
.heading-5, h5,
.heading-6 h1, h1.heading-6, .heading-6 h2, h2.heading-6, .heading-6 h3, h3.heading-6, .heading-6 h4, h4.heading-6, .heading-6 h5, h5.heading-6, .heading-6 h6, h6.heading-6, .heading-6 p, p.heading-6, .heading-6 span, span.heading-6,
.heading-6, h6 {
  @include heading-4;
}

/**
*** Copy
**/

/*

Copy Type Elements

blockquote,
dt,
dd,
figcaption,
li,
p,
a,
span,
label,
textarea,
select,
input,
button,
table,
th,
td,
tr,
legend,

*/

dd, figcaption, li, p, a, span, label, textarea, select, input, button, table, td, tr, blockquote, legend, th, dt, b, strong {
    @include body-copy;
}

legend, th, dt, b, strong, .bold-text {
  font-weight: $medium-weight;
}