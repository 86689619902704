.cmp-text {
    p {
        margin: 24px 0;
        +h1,
        +h2,
        +h3,
        +h4,
        +h5,
        +h6 {
            margin-top: -5px;
            @media only screen and (min-width: $screen-tablet) {
                margin-top: 40px;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        +p {
            margin-top: -5px;
        }
    }
}