//== Base

html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  margin: 0;
}

.dark {
  background-color: $color-background-dark;
}

.light {
  background-color: $color-background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 16px 0;
}

blockquote,
dt,
dd,
figcaption,
li,
p,
a,
span,
label,
textarea,
select,
input,
legend {
  padding: 0;
  margin: 8px 0;
}

p {
  word-break: break-word;
}

/**
*** Form Elements
**/

.selectInput,
select {
  background-repeat: no-repeat;
  background-position: right;
  background-color: $white;
  appearance: none;
  padding: 10px 50px 10px 10px;
  min-width: 260px;
  position: relative;
  border: 1px solid $ledcor-grey;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' style='border-left: 1px solid %23727070%3B'%3E%3Crect width='40' height='40' fill='%23EAEAEA'/%3E%3Cpath d='M10 0h0L0 8l10 8V11.2L6 8l4-3.2V0Z' transform='translate(12 27) rotate(270)' fill='%232981eb'/%3E%3C/svg%3E");

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' style='border-left: 1px solid %23727070%3B'%3E%3Crect width='40' height='40' fill='%232981eb'/%3E%3Cpath d='M10 0h0L0 8l10 8V11.2L6 8l4-3.2V0Z' transform='translate(12 27) rotate(270)' fill='%23fff'/%3E%3C/svg%3E");
  }
}

.selectInput {
  position: relative;
  background-image: none;

  &:hover {
    background-image: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
  }

  &::before {
    background-color: $ledcor-grey-15;
    border: 0;
    border-left: 1px solid $ledcor-grey;
    transition: background-color 150ms ease-out;
  }

  &::after {
    background-image: url($ledcor-arrow-left-blue);
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(90deg) translateX(1px) scale(1.1);
    top: 2px;
    transition: transform 150ms ease-out;
  }

  &:hover::before {
    background-color: $vhfc-blue;
  }

  &:hover::after {
    transform: rotate(-90deg);
    background-image: url($ledcor-arrow-left-white);
  }
}

/**
*** CTAs
**/

@mixin trivial {
  font-size: inherit;
  line-height: inherit;
  font-weight: $medium-weight;
  text-decoration: none;
  color: $vhfc-blue;
  text-decoration-color: transparent;
  text-underline-offset: 0.25rem;
  background: transparent;
  border: transparent;
  transition: text-decoration-color 300ms ease-out;
  &:focus,
  &:hover {
    cursor: pointer;
  }
}

@mixin primary {
  @include trivial;
  font-size: $text-16;
  line-height: $text-24;
  @media (min-width: $screen-tablet) {
    font-size: $text-24;
    line-height: $text-36;
  }
}

a,
button {
  @include primary;
}

.primary {
  > a,
  > button {
    @include primary;
  }
}

button.trivial,
a.trivial,
.trivial {
  @include trivial;
}

blockquote,
dt,
dd,
figcaption,
li,
span,
label,
select,
p {

  a,
  button,
  a.trivial,
  button.trivial,
  .trivial {
    @include trivial;
  }

}

.dark {

  blockquote,
  dt,
  dd,
  figcaption,
  li,
  span,
  label,
  select,
  p {

    a,
    button,
    a.trivial,
    button.trivial,
    .trivial {
      color: $vhfc-lightest-blue;
    }

    a:focus,
    a:hover,
    button:focus,
    button:hover,
    a.trivial:focus,
    button.trivial:focus,
    a.trivial:hover,
    button.trivial:hover,
    .trivial:focus,
    .trivial:hover {
      color: $vhfc-lightest-blue;
    }
  }
}


button.tertiary,
a.tertiary,
.tertiary {
  @include primary;
}

button.secondary,
a.secondary,
.secondary {
  @include primary;
}

body:not(.xf-web-container) {
  @media only screen and (min-width: 1270px) {
    .max-width-1270 {
      &.aem-GridColumn.container {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        margin-left: 0;

        >.cmp-container {
          width: 100%;
          max-width: 1270px;
          margin-left: 0;
        }
      }
    }
  }
}

body:not(.xf-web-container) {
  @media only screen and (min-width: 1400px) {
    .max-width-1400 {
      &.aem-GridColumn.container {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        margin-left: 0;

        >.cmp-container {
          width: 100%;
          max-width: 1400px;
          margin-left: 0;
        }
      }
    }
  }
}

.focus-within {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 1px;
}

.history-label-text {
  @extend .heading-1;
  font-style: italic;
  color: $ledcor-blue;

  sub {
    margin-left: -2px;
  }

  sup {
    margin-left: 7px;
  }
}

// Set Default Margins for components
.container.Container > .cmp-container > .aem-Grid {
  > div:not(.teaser-hero, .teaser-title, .separator, .container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl, .bottom-margin-none) {
    @extend .bottom-margin-md;
  }
  > .container > .cmp-container > .aem-Grid {
    > div:not(.teaser-hero, .teaser-title, .separator, .container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl, .bottom-margin-none) {
      @extend .bottom-margin-md;
    }
    > .container > .cmp-container > .aem-Grid {
      > div:not(.teaser-hero, .teaser-title, .separator, .container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl, .bottom-margin-none) {
        @extend .bottom-margin-md;
      }
      > .container > .cmp-container > .aem-Grid {
        > div:not(.teaser-hero, .teaser-title, .separator, .container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl, .bottom-margin-none) {
          @extend .bottom-margin-md;
        }
      }
    }
  }

  // Prevent default margin on first component (unless manually set)
  > div:first-child:not(.container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl) {
    @extend .bottom-margin-none;
  }
  > .container:first-child > .cmp-container > .aem-Grid {
    > div:first-child:not(.container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl) {
      @extend .bottom-margin-none;
    }
    > .container:first-child > .cmp-container > .aem-Grid {
      > div:not(.container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl) {
        @extend .bottom-margin-none;
      }
      > .container:first-child > .cmp-container > .aem-Grid {
        > div:not(.container, .bottom-margin-xs, .bottom-margin-sm, .bottom-margin-md, .bottom-margin-lg, .bottom-margin-xl, .bottom-margin-xxl) {
          @extend .bottom-margin-none;
        }
      }
    }
  }
}

// Skip Navigation Link Styles
.accessible-link a {
  @extend .visually-hidden;
  margin: 0px;
  padding: calc($base-unit / 2) $base-unit;
}
.button.accessible-link a.cmp-button {
  color: $white;
  border-color: $white;
}

.accessible-link a:focus,
.accessible-link a:focus-visible,
.accessible-link a:active,
.aem-AuthorLayer-Edit .accessible-link a {
  clip: auto !important;
  clip-path: none !important;
  height: auto !important;
  overflow: hidden !important;
  width: auto !important;
  margin-top: -20px;
}

#main-header > .aem-Grid > .Content.aem-GridColumn--default--none:last-child {
  height: 0;
}